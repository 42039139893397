import React, { useState, useEffect, createRef } from 'react';
import Axios from 'axios';
import Slider from 'react-slick';

import { LinkModal } from "kh-common-components";

import { modalContents } from "../../constants/modal-content";

import flybuys1pt from "../../images/flybuys/flybuys-1pt.png";
import flybuysPoints10k1pt from "../../images/flybuys/flybuys-points-plus-bonus.svg";

import "styles/pages/product-select-summer.css";

function FlybuysPlans(props) {

    const [plansData, setPlansData] = useState(null);

    const carouselClass = 'mer-media-card-carousel mer-media-card-carousel-react container-fluid-constrained mer-slick-slider--height-equal';

    let mediaCardRef = [];

    const getPlanData = async () => {

        if (plansData !== null) {
            return;
        }

        try {
            const plans = await Axios.get(process.env.PRODUCTS_API);
            const reversed = plans.data.reverse();
            setPlansData(reversed);
        } catch (error) {
            console.log('Error getting plan details');
        }
    };

    const settings = {
        // rows: 0, // causes memory leak
        // lazyLoad: true,
        arrows: false,
        dots: true,
        infinite: false,
        initialSlide: 1,
        swipeToSlide: true,
        draggable: true,
        mobileFirst: true,
        transformEnabled: false,
        useTransform: false,

        speed: 800,
        slidesToShow: 3,

        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '100px',

        dotsClass: 'd-flex slick-dots',
        className: carouselClass,

        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '100px'
                }
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '100px'
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '1px'
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '200px'
                }
            },
                {
                breakpoint: 860,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '150px'
                }
            },
            {
                breakpoint: 639, // 639 less
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 550, // 639 less
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '30px'
                }
            }
        ]

    };

    useEffect(() => {
        getPlanData();

        mediaCardRef.forEach(referenceItem => {
            if(referenceItem.current) {
                referenceItem.current.parentElement.classList.add('col', 'mer-card', 'mer-card-media', 'mdc-card');
            }
        });

    }, [plansData]);

    return(
        <>
            { Array.isArray(plansData) && plansData.length > 0 &&
                <section id="plans" 
                        className="mer-bg--ui-light mer-py-ju">
                    <div className="container">

                        <div className="row align-items-center justify-content-center">
                            <div className="col-10 col-sm-8">
                                <div className="content text-center">
                                    <h2 className="mt-2">Natural gas pricing</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <Slider {...settings}>

                        {plansData.map((plan, index) => {
                            const newRef = createRef();
                            mediaCardRef.push(newRef);

                            let isStandard = plan.isStandard ? 'standard' : 'non-standard';
                            let discount = plan.isStandard ? plan.onTimePaymentDiscount : plan.discount;                            
                            let discountDecimalPlace = "";
                            let tcUrl = 'plan_details_and_terms_' + plan.key;

                            if (plan.key == 2) {
                                discount = 35;
                                tcUrl += '_summer';
                            }
                                    
                            if (!Number.isNaN(discount) && !Number.isInteger(discount)) { // if discount is a number with decimal places
                                // split whole number and decimal place
                                const discountInStr = discount.toString();
                                const dotIndex = discountInStr.indexOf(".");
                                discountDecimalPlace = discountInStr.substring(dotIndex, dotIndex + 2); // only keeping 1 decimal place 
                                discount = parseInt(discount); // whole number
                            }

                            let promoCode = props.promoCode ? props.promoCode : '';
                            if (promoCode === '') {
                                if (plan.key == 2) {
                                    promoCode = 'SUMMERFLYBUYS10K24';
                                }
                                else if (plan.key == 1) {
                                    promoCode = 'SUMMERSFCFLYBUYS10K24';
                                }
                            }

                            return (
                                <div ref={newRef} className={`mer-card-media-react__container mer-plan-card mer-card mdc-card text-center py-4 ${plan.key === '2' ? 'product-select-summer online-offer-only' : ''} ${plan.key === '537' ? 'product-select-summer flybuys-monthly-energiser' : ''}`} key={index}>
                                    {plan.key === '2' &&
                                        <h5 className="mer-bg--primary-blue mer-color--gs-white online-offer-only-sup">Online only offer<sup>^</sup></h5>
                                    }
                                    <h4 className="mer-plan-card__title">{plan.name}</h4>
                                    <p className="mer-plan-card__description">
                                        <span className="mer-plan-card__discount">
                                            {discount}
                                            {discountDecimalPlace !== "" && <span className="mer-plan-card__discount-decimal">.5</span>}
                                            <span className="mer-plan-card__discount-percent">%</span>
                                        </span>                                    
                                        <span className="mer-plan-card__discount-description">off gas usage charges {plan.onTimePaymentDiscount !== 0 ? "when you pay on time" : "for at least 12 months"}</span>
                                    </p>
                                    <div className={plan.key === '537' ? 'col-5 mx-auto py-0 mer-my-sm' : 'col-10 mx-auto py-0 mer-my-sm'}>
                                        <div className="mer-panel__images d-flex align-items-center align-self-center justify-content-center">
                                            <img
                                                className="mer-panel__image"
                                                src={plan.key === '537' ? flybuys1pt : flybuysPoints10k1pt }
                                                alt="Flybuys points"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-10 mx-auto py-0 py-md-1 py-lg-0 mer-my-sm">
                                        <div className="text-center mer-mt-sm">
                                            <strong className="mer-color--primary-blue" dangerouslySetInnerHTML={{ __html: plan.key === '537' ? 'Great value gas with Flybuys points on every natural gas bill.<sup>-</sup>' : 'You can collect 10,000 bonus Flybuys points when you select this plan.<sup>+</sup>' }} />
                                        </div>
                                    </div>
                                    <ul className="mer-list mer-color--primary-navy mer-border--bottom mer-border--color-gs-lighter m-0 px-2 py-3 mb-4">
                                        <li><strong>Direct debit</strong></li>
                                        <li><strong>{plan.isStandard ? 'Quarterly bills' : 'Easy monthly instalments'}</strong></li>
                                        <li><strong>{plan.isStandard ? 'Email or paper bills' : 'Email bills'}</strong></li>
                                        <li>No setup or exit costs</li>
                                        <li>No lock-in contracts</li>
                                    </ul>
                                    <div className="content mx-4 text-center">
                                        <a href={plan.key === '537' ? `/sign-up/product/${plan.key}` : `/sign-up/promo/${promoCode}/${plan.key}`}
                                            className="mer-button mer-button--primary mer-button--size-full-width mb-3">
                                            <span className="mer-color--gs-white">Choose plan</span>
                                        </a>
                                        <small className="ng-plan-details text-center">
                                            <LinkModal
                                                modalContents={modalContents}
                                                useModal="true"
                                                title={plan.isStandard ? 'View plan details and standard terms' : 'View plan details and non-standard terms'}
                                                displayContent={plan.isStandard ? 'View plan details and standard terms' : 'View plan details and non-standard terms'}
                                                modalTitle={plan.isStandard ? 'View plan details and standard terms' : 'View plan details and non-standard terms'}
                                                modalContentName={`${tcUrl}`}
                                                okayButtonLabel="Okay"
                                            />
                                        </small>
                                    </div>
                                </div>
                        )})}

                    </Slider>
                        
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-10 col-sm-8 col-md-6 col-lg-8">
                                <div className="text-center mer-mt-sm">
                                    <p className="mb-2 pb-0">
                                        <small>
                                            <LinkModal
                                            modalContents={modalContents}
                                            useModal="true"
                                            displayContent="View prices and fees for all natural gas customers"
                                            title="View prices and fees for all natural gas customers"
                                            modalTitle="View prices and fees for all natural gas customers"
                                            modalContentName="standard_prices_and_fees"
                                            okayButtonLabel="Close"
                                            />
                                        </small>
                                    </p>                                 

                                    <p className="mer-color--primary-blue mer-font--weight-bolder mt-sm-2 mer-typography--headline5"><sup>^</sup>Offer available online only to new and moving customers. Offer ends 30 April 2025.</p>                                    
                                    <p>
                                        <small>
                                            <LinkModal
                                            modalContents={modalContents}
                                            useModal="true"
                                            displayContent={<span><sup>+-</sup>Terms and conditions apply</span>}
                                            title="Terms and conditions"
                                            modalTitle="Terms and conditions"
                                            modalContentName="flybuys_10k_terms_conditions"
                                            okayButtonLabel="Okay"
                                            />
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
);
}

export default FlybuysPlans;